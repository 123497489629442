.small {
}

.medium {
  display: none;
}

.large {
  display: none;
}

@media (min-width: 545px) {
  .small {
    display: none;
  }

  .medium {
    display: block;
  }
}

@media (min-width: 1125px) {
  .medium {
    display: none;
  }

  .large {
    display: block;
  }
}
