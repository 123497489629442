.customBorder {
  background-image: repeating-linear-gradient(
      181deg,
      transparent 22px,
      transparent 32px,
      #ffffff 32px
    ),
    repeating-linear-gradient(271deg, transparent 22px, transparent 32px),
    repeating-linear-gradient(1deg, transparent 22px, transparent 32px),
    repeating-linear-gradient(
      91deg,
      #ffffff,
      #ffffff 12px,
      transparent 12px,
      transparent 17px,
      #ffffff 17px
    );
  background-size: 3px 100%, 100% 3px, 3px 100%, 100% 3px;
  background-position: 0 0, 0 0, 100% 0, 0 100%;
  background-repeat: no-repeat;
  border: none;

  height: 2px;
  overflow: hidden;
}
